export const realtorData = {
  email: 'maismapropiedades@gmail.com',
  phone:'+56966437996'
};

export const footerData = {
  email: 'maismapropiedades@gmail.com',
  phone:'966437996',
  wsp:'966437996',
}
